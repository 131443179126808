import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

import Hero from "../../components/hero--product"
import Content from "../../components/content-with-image"
import H4 from "../../components/Utility/heading4"
import CenteredCTA from "../../components/cta--centered"
import { BVLoader, BVReviews } from "@tyson-foods/react-component-library"
import Collapsable from "../../components/Collapse/Collapse"
import CTA from "../../components/cta"

export default class ProductDetailPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Plant Based Burger Made with Plant Protein</title>
          <meta
            name="description"
            content="Our Raised & Rooted™ Plant Based Burger is packed full of protein and 100% delicious. Swap out your regular burger for some plant based meat!"
          />
          <meta itemProp="name" content="Plant Based Burger Made with Plant Protein" />
        </Helmet>
        <Layout>
          <BVLoader
            clientName="raisedandrooted"
            siteID="main_site"
            env={process.env.GATSBY_BAZAARVOICE_ENV}
          />
          <div itemScope itemType="http://schema.org/Product">
            <Hero
              heroDirection="reverse"
              heroclassName="single"
              headingTitleColor1="#006B2D"
              headingText1="Plant Based Burger"
              headingColor1="#006B2D"
              bodyColor="#363636"
              bodyText="Make space on the grill for our juiciest burger to-date. It may be made with 100% plant based protein, but it’s still loaded with that classic, backyard taste you crave."
              itemColor="#006B2D"
              valueText1="21"
              unitText1="g"
              labelText1="Protein (per serving)"
              valueText2="1"
              unitText2="g"
              labelText2="Fiber per serving"
              valueText3="75"
              unitText3="%"
              labelText3="Less Saturated Fat*"
              dataImg4="/leaf.svg"
              labelText4="Soy Free"
              dataImg5="/leaf.svg"
              labelText5="Made With Plant Based Protein"
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
              footnoteText="*Compared to USDA data for 80% Lean/20% Fat Beef"
              footnoteColor="#006B2D"
              productID="00053400000354"
            >
              <img src="/raised-and-rooted-plant-based-burger-708x721.png" alt="Raised And Rooted Plant Based Burger" />
            </Hero>
            <Content
              ctaclassName="reverse"
              ctaBGColor="#97D700"
              bodyColor="#006B2D"
              imgSrc="/raised-and-rooted-plant-based-burger-708x721-ls.png"
              altText="Raised And Rooted Plant Based Burger"
              headingText2="WHAT’S INSIDE?"
              headingColor2="#006B2D"
              blurb="Our Raised & Rooted™ Burger is plant based, but loaded with that juicy, char-grilled taste."
            >
              <h4>NUTRITION</h4>
              <p>
              21 grams of protein per serving, 75% less saturated fat*, and soy free.
              </p>
              <p>
              *compared to USDA 80% lean/20% fat beef.
              </p>
              <h4>TASTE</h4>
              <p>
              This beefy mountain of plant protein can hang with the best of them. No joke. 
              Oozing with juicy, burger-y flavor like no other plant based pa"y, all you’ll need to worry about is how high you’re going to pile those toppings.
              </p>
            </Content>

            <div className="nutrition">
              <div>
                <Collapsable
                  className={"nutrition__prepared"}
                  text={"Preparation Instructions"}
                  collapsed={false}
                >
                  <b>SKILLET</b>
                  <p>
                  1. Remove burger(s) from packaging and place in a greased non-stick skillet.
                  </p>
                  <p>
                  2. Cook over medium heat for 10-12 minutes or until burger is thoroughly cooked*, turning occasionally.
                  </p>
                  <b>GRILL</b>
                  <p>
                  1. Preheat greased grill on medium heat. Remove burger(s) from packaging and place on preheated grill.
                  </p>
                  <p>
                  2. Grill for 10-12 minutes or until thoroughly cooked*, turning occasionally.
                  </p>
                  <br>
                  </br>
                  <p>
                  Note: Burger must reach a minimum of 165°F as measured with an instant read thermometer in several places. 
                  *When fully cooked, the burger should still be a reddish pink color.
                  </p>
                </Collapsable>
                <br />
                <Collapsable
                  className={"nutrition__details"}
                  text={"Nutrition"}
                  collapsed={true}
                >
                  <p>2 Servings Per Container</p>
                  <p>Serving Size 1 burger (112g)</p>
                  <p>Amount Per Serving</p>
                  <hr />
                  <table>
                    <tr>
                      <td colspan="2">Calories 280</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="2">% Daily Value *</td>
                    </tr>
                    <tr>
                      <td>Total Fat 18g</td>
                      <td>23%</td>
                    </tr>
                    <tr>
                      <td className="indent">Saturated Fat 1.5g</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Trans Fat 0g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Polyunsurated Fat 5g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Monounsurated Fat 10g
                      </td>
                    </tr>
                    <tr>
                      <td>Cholesterol 0mg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Sodium 610mg</td>
                      <td>27%</td>
                    </tr>
                    <tr>
                      <td>Total Carbohydrate 8g</td>
                      <td>3%</td>
                    </tr>
                    <tr>
                      <td className="indent">Dietary Fiber 1g</td>
                      <td>4%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Total Sugars 1g
                      </td>
                    </tr>
                    <tr>
                      <td className="indent">Includes 0g Added Sugars</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Protein 21g</td>
                      <td>30%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>Vitamin D 0mcg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Calcium 50mg</td>
                      <td>4%</td>
                    </tr>
                    <tr>
                      <td>Iron 5.4mg</td>
                      <td>30%</td>
                    </tr>
                    <tr>
                      <td>Potassium 890mg</td>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        *The % Daily Value tells you how much a nutrient in a serving of food contributes to a daily diet.
                        2,000 calories a day is used for general nutrition advice.
                      </td>
                    </tr>
                  </table>
                </Collapsable>
              </div>
              <Collapsable
                className={"nutrition__ingredients"}
                text={"Ingredients"}
                collapsed={true}
              >
                <p>
                WATER, TEXTURED PEA PROTEIN [PEA PROTEIN ISOLATE, TAPIOCA
                STARCH, VITAMIN E (MIXED TOCOPHEROLS)], CANOLA OIL, PEA PROTEIN ISOLATE,
                CONTAINS 2% OR LESS: CALCIUM SULPHATE DIHYDRATE, CITRIC ACID, DEHYDRATED
                GARLIC, DEXTROSE, FRUIT JUICE COLOR, GARLIC POWDER, GLYCERINE, MALTODEXTRIN,
                MODIFIED CELLULOSE, MODIFIED CORN STARCH, NATURAL FLAVOR, NATURAL FLAVOR BEEF
                BURGER TYPE [NATURAL FLAVOR, SALT, YEAST EXTRACT, MALTODEXTRIN (FROM CORN),
                SOYBEAN OIL, XANTHAN GUM], NATURAL SMOKE FLAVORING, NATURAL UMAMI FLAVOR MEATY
                TYPE [YEAST EXTRACT, NATURAL FLAVOR, SALT, MALTODEXTRIN (FROM POTATO),
                MALTODEXTRIN (FROM CORN), DEXTROSE, SUCCINIC ACID, SUGAR], ONION JUICE CONCENTRATE,
                POTATO STARCH, PROPYLENE GLYCOL, ROSEMARY EXTRACT, SALT, SEA SALT, SODIUM
                ALGINATE, SOYBEAN OIL, SPICE, SUGAR, SUNFLOWER OIL, TETRASODIUM PYROPHOSPHATE,
                TOMATO JUICE CONCENTRATE, TOMATO POWDER, TORULA YEAST, VEGETABLE JUICE COLOR,
                VINEGAR, YEAST EXTRACT.
                </p>
              </Collapsable>
            </div>
            <div id="reviews" className="reviews">
              <BVReviews productID="00053400000354" />
            </div>
            <CenteredCTA
              ctaBottomMargin="30px"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
